import { template, getLocalStorage, setLocalStorage } from '@shein/common-function'
import dayjs from 'dayjs'
const { SiteUID, user, lang } = gbCommonInfo
import schttp from 'public/src/services/schttp'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

// 地址缓存
export const setCache = (item, isUserHandle = '0') => {
  const realSetData = {}
  const addressCache = {}
  const addressCookie = {
    addressId: item?.address_id || '', // 详细地址id
    countryId: item?.country_id || '', // 国家id
    stateId: item?.state_id || '', // 省id
    cityId: item?.city_id || '', // 城市id
    districtId: item?.district_id || '', // 区id
    createdTime: new Date().getTime(), // 缓存生成时间
    memberId: user?.member_id || '', // 用户id
    loginTimeLast: user?.login_time_last || '', // 用户最后登录时间
    isUserHandle, // 是否为用户主动操作 是：1，否：0
    siteUid: SiteUID
  }
  Object.keys(addressCookie).forEach(val => {
    if (addressCookie[val]) {
      realSetData[val] = addressCookie[val]
    }
  })
  // 详细地址缓存... 省市区...
  if (item?.country_id) {
    addressCache[item?.country_id] = item?.country
  }
  if (item?.city_id) {
    addressCache[item?.city_id] = item?.city
  }
  if (item?.state_id) {
    addressCache[item?.state_id] = item?.state_province
  }
  if (item?.district_id) {
    addressCache[item?.district_id] = item?.district
  }
  setCacheBySetLocalStorage('addressCookie', realSetData)
  if (JSON.stringify(addressCache) !== '{}') {
    localStorage.setItem('address_cache', JSON.stringify(addressCache))
  } else {
    localStorage.removeItem('address_cache')
  }
}

export const isValidCache = (cache) => {
  // 判断是否同一个用户
  if (cache.memberId != user?.member_id) return false
  // 判断是否退出登录过
  if (cache.loginTimeLast != user?.login_time_last) return false
  // 判断是否同一个站点
  if (cache.siteUid != SiteUID) return false
  return true
}
// wiki: pageId=1133687475
// 时效最快
const sortTypeFun = () => {
  let dic = lang !== 'ru' ? {
    'express_shipping': 4, // 快递
    'express_shoptransit': 3, // 快递店配
    'stand_shipping': 2, // 标准
    'shop_transit': 1, // 店配
    'expedited_shipping': 0 //平邮
  } : {
    'express_shipping': 2, // 快递
    'express_shoptransit': 3, // 快递店配
    'stand_shipping': 1, // 标准
    'shop_transit': 4, // 店配
    'expedited_shipping': 0 //平邮
  }
  return (a, b) => {
    return dic[b.type] - dic[a.type]
  }
}
// 当命中门槛最低优先的分支时，若多个邮寄方式运费一样，则按照：快递-快递店配-店配-标准-平邮
const sortPriceFun = () => {
  let dic = {
    'express_shipping': 4, // 快递
    'express_shoptransit': 3, // 快递店配
    'stand_shipping': 1, // 标准
    'shop_transit': 2, // 店配
    'expedited_shipping': 0 //平邮
  }
  return (a, b) => {
    const value1 = a['starting_price_obj'] && a['starting_price_obj']['amount'] || 0
    const value2 = b['starting_price_obj'] && b['starting_price_obj']['amount'] || 0
    if (value1 === value2) {
      return dic[b.type] - dic[a.type]
    } else {
      return value1 - value2
    }
  }
}

export const shippingPolicySortFn = (shippingAbt) => {
  return shippingAbt === 'quick' ? sortTypeFun() : sortPriceFun()
}

/**
 * 对象数组从小到大排序
 */
export const createComparionFun = (propertyName, amount) => {
  return (object1, object2) => {
    const value1 = object1[propertyName] && object1[propertyName][amount] || 0
    const value2 = object2[propertyName] && object2[propertyName][amount] || 0
    if (value1 < value2) {
      return -1
    } else if (value1 > value2) {
      return 1
    } else {
      return 0
    }
  }
}

export const computedExpectTime = (item = {}, addTag = false, text, onlyExpectTime = false) => {
  if (!item.expectTime || typeof window === 'undefined') {
    return ''
  }
  let expectTime = ''
  let timeFormat = ''
  if (['en', 'il', 'us', 'asia'].includes(lang)) {
    timeFormat = 'MM/DD/YYYY'
  } else if (['au', 'mx', 'hk', 'br', 'ca', 'eur', 'cl', 'ma', 'sg', 'za', 'nz', 'pl', 'ph', 'my', 'ch'].includes(lang)) {
    timeFormat = 'DD/MM/YYYY'
  } else if (['jp', 'ar', 'tw', 'maar'].includes(lang)){
    timeFormat = 'YYYY/MM/DD'
  } else {
    timeFormat = 'DD/MM/YYYY'
  }
  if (item.expectTime?.includes('-')) {
    const [start, end] = item.expectTime.split('-')
    const startDate = dayjs(item.currentTime).add(8, 'hour').add(start, 'day')
    const endDate = dayjs(item.currentTime).add(8, 'hour').add(end, 'day')
    expectTime = startDate?.utc?.() ? `${startDate.utc().format(timeFormat)} - ${endDate.utc().format(timeFormat)}` : ''
  } else {
    const endData = dayjs(item.currentTime).add(8, 'hour').add(item.expectTime, 'day')
    expectTime = endData?.utc?.() ? endData.utc().format(timeFormat) : ''
  }
  expectTime = addTag ? `<span>${expectTime}</span>` : expectTime
  return onlyExpectTime ? expectTime : template(expectTime, text)
}


export const getLocalStorageAndRemoveCookie = (key) => {
  // TODO DEL 2023-9-18 保持一个月
  let cache = ''
  try {
    cache = getLocalStorage(key)
  } catch (error) {
    cache = ''
  }
  return cache
}

export const setCacheBySetLocalStorage = async (key, data) => {
  const res = await schttp({
    method: 'POST',
    url: '/api/common/busineseCache/update',
    data: {
      cacheData: data,
      scanType: 'addrcache'
    }
  })
  if (res?.code == '0') {
    setLocalStorage({
      key,
      value: data
    })
  }
}
