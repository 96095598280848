const abcCommentAdapter = (data, { page }) => {
  if (data?.info?.comment_info !== undefined) {
    const { comment_info, comment_num, onlyCommentNumShow, comment_tags } = data?.info || {}
    data.info.commentInfo = commentInfoCalc(comment_info, { page })
    data.info.commentInfoTotal = comment_num
    data.info.commentNumStr = onlyCommentNumShow
    data.info.commentFuzzy = !!onlyCommentNumShow
    data.info.spuTag = comment_tags?.map?.(item => {
      return {
        ...item,
        numStr: item.tagCmtNumShow,
        fuzzy: !!item.tagCmtNumShow,
      }
    }) || []
  }
  return data
}

const buyShowAdapter = (data, { page }) => {
  if (!data.info?.commentList?.comment_info?.length) return null
  return {
    code: data?.code,
    info: {
      commentInfo: commentInfoCalc(data.info.commentList.comment_info, { page }),
      hasNextFlag: data.info?.commentList?.hasNextFlag,
    }
  }
}

const freeTrailAdapter = (data) => {
  if (data?.info?.free_trial_list !== undefined) {
    const { free_trial_list } = data.info || {}
    data.info.free_trial_list = free_trial_list?.map?.(item => {
      return {
        ...item,
        isNewSizeComp: true,
        attr_language_value: skuSaleNameCalc(item?.sku_sale_attr),
      }
    })
  }
  return data
}

const skuSaleNameCalc = (skuSaleAttr = []) => {
  if (!skuSaleAttr?.length) return ''
  const attrs = []
  skuSaleAttr.forEach(attr => {
    const { attr_name, attr_value_name, is_main } = attr || {}
    if (is_main) {
      attrs.unshift(`${attr_name}:${attr_value_name}`)
      return
    }
    attrs.push(`${attr_name}:${attr_value_name}`)
  })
  return attrs.join(' / ')
}

const commentInfoCalc = (commentList = [], { page }) => {
  if (!commentList?.length) return []
  return commentList.map(item => {
    const { contentTag, sku_sale_attr, selectTag, connetLabelTips } = item || {}
    return {
      ...item,
      translateCon: '',
      translateConTags: [],
      translateConSelectTags: [],
      translateLang: '',
      page,
      content_tag: contentTag,
      select_tag: selectTag,
      same_flag: connetLabelTips ? 1 : 0,
      isNewSizeComp: true,
      goods_attr: skuSaleNameCalc(sku_sale_attr)
    }
  })
}

export default {
  abcCommentAdapter,
  freeTrailAdapter,
  buyShowAdapter,
}
