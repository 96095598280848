import { computed } from 'vue'
import { EItemConfigType } from '../const'
import { state } from '../state'
import { getMergeConfigList } from '../utils'

/**
 * 服务端异步配置项
 * services/goods/ProductConfigManager/ItemConfig/index.js
 */

export const commonConfig = computed(() => state.value.commonConfig)

const _baseConfig = computed(() =>
  getMergeConfigList([
    state.value.itemConfigAsync.Base,
    {
      _isPaidUser: state.value.isPaidUser,
      // _loaded: state.value.loaded,
      configReady: state.value.loaded, // 底部角标有值后进行计算宽度是否展示。
    },
  ])
)

/**
 * 商详推荐商品项专属配置
 */
export const detailRecommendConfig = {
  moduleFrom: 'recommend',
}

export const ItemConfigMap = {
  [EItemConfigType.Pic1]: computed(() => {
    return getMergeConfigList([
      _baseConfig.value,
      state.value.itemConfigAsync[EItemConfigType.Pic1],
      {
        _isNew: true, // 新商卡
        showDetailRecNewCard: true, // 新商卡

        disableMainimgJump: true, // 点击禁止跳转
        useOwnClickAddBagAnalysis: true // 是否使用自己的点击加车icon埋点
      },
    ])
  }),
  // 1
  [EItemConfigType.Pic2]: computed(() => {
    return getMergeConfigList([
      _baseConfig.value,
      {
        _isNew: true, // 新商卡
        showDetailRecNewCard: true, // 新商卡

        useOwnClickAddBagAnalysis: true // 是否使用自己的点击加车icon埋点
      },
      state.value.itemConfigAsync[EItemConfigType.Pic2],
    ])
  }),
  // 1
  [EItemConfigType.Pic2V1]: computed(() => {
    return getMergeConfigList([
      _baseConfig.value,
      state.value.itemConfigAsync[EItemConfigType.Pic2V1],
      {
        // // 色块点击事件触发的声明式埋点配置
        // itemColorDAEventClickId: '2-6-6',
        // itemColorDAEventExposeId: '2-6-7',
        // showFeedbackPoskey: 'ProductDetailYouMayAlsoLikeExpandPop',

        showUserBehaviorLabel: true,
        showPromotion: false, // 显示促销信息
        showLeftStock: false, // 剩余库存数量, 0-10(不含首尾) 例: only 2 left
        showRankingList: true,
        showMultiColor: false, // 是否展示多颜色 colorPoskeyParam == 'type=multicolor_select'
        showMultiColorIcon: false, // 是否展示的是标签类型的多颜色 colorPoskeyParam == 'type=multicolor_show'

        showSellingPoint: true,  // 显示卖点角标
        showBadge: true,
        showSheinClubDiscountValue: true,
        hideRetailPrice: true, // 隐藏划线价格【折扣标签展示在价格旁边需求】
        hideDiscountBadge: true, // 隐藏折扣角标
        showDiscountLabelAfterPrice: true, // 折扣价标展示在价格右边
        showBeltLabel: true, // 腰带
        showEstimatedPriceReachThreshold: true,  // v2 到手价标签
        showEstimatedPriceOnSale: true, // v3 到手价标签
        showPriceDiscountDesc: true,  // 历史低价、保留款跟价款标签

        useOwnClickAddBagAnalysis: true // 是否使用自己的点击加车icon埋点
      }
    ])
  }),
  // 1
  [EItemConfigType.Pic3]: computed(() => {
    return getMergeConfigList([
      _baseConfig.value,
      state.value.itemConfigAsync[EItemConfigType.Pic3],
      {
        _isNew: true, // 新商卡
        showDetailRecNewCard: true, // 新商卡
        showDetailMultiNewCard: true, // 新商卡

        showTitle: false,
        showLocalSeller: true,
        showPlusSize: true,
        showNewFlashPriceOnSale: true,
        showQuickShip: false,
        showSellingPoint: false,
        hideDiscountLabel: true, // 价格过长的时候隐藏超出的标签, 超出一行展示的时候隐藏超出的标签
        showNewDiscountLabel: false,
        showAddToBoard: false,
        showSheinClubDiscountValue: true,
        showSheinClubDiscountValueAfterPrice: false,

        // 业务层
        disableMainimgJump: true, // 点击禁止跳转
        useOwnClickAddBagAnalysis: true // 是否使用自己的点击加车icon埋点
      }
    ])
  }),
  // 1
  [EItemConfigType.Pic3V1]: computed(() => {
    return getMergeConfigList([
      _baseConfig.value,
      state.value.itemConfigAsync[EItemConfigType.Pic3V1],
      {
        showPromotion: true, // 促销
        showSellingPoint: true, // 显示卖点角标
        showBadge: true, // 显示ccc-tsp标签（左上角，左下角，商品底部）
        hideDiscountBadge: true, // 隐藏折扣角标
        showDiscountLabelAfterPrice: true, // 折扣价标展示在价格右边
        showTitle: false,
        showSeriesBrand: true, // 品牌系列
        showLocalSeller: true, // 服务类标签
        showPlusSize: true, // 显示大码
        showNewFlashPriceOnSale: true, // 新型闪购价，占用原来的销售价
        showRankingList: false, // 榜单

        // unifiedRecommendPrdConfig
        hideRetailPrice: true, //  pricemember?.param?.price === 'notshow'
        showSheinClubDiscountValue: false, //  pricemember?.param?.membersize !== 'small'
        showSheinClubDiscountValueAfterPrice: true, // pricemember?.param?.membersize === 'small'
        showMultiColor: false, // YouMayAlsoLikeMultiColor?.param === 'type=multicolor_select'
        showMultiColorIcon: false, //YouMayAlsoLikeMultiColor?.param === 'type=multicolor_show'

        // 业务层
        showAddBagBtn: true, // 加车按钮
        disableMainimgJump: true, // 点击禁止跳转
        useOwnClickAddBagAnalysis: true // 是否使用自己的点击加车icon埋点
      }
    ])
  }),
  // 1
  [EItemConfigType.sPic3OnlyPrice]: computed(() => {
    return getMergeConfigList([
      _baseConfig.value,
      // state.value.itemConfigAsync[EItemConfigType.sPic3OnlyPrice],
      {
        showTitle: false,
        hideSoldOut: false,
        hideFlashSaleIcon: true, // 隐藏闪购图标
        hideLimitedTimeIcon: true, // 隐藏闪购计时图标
        showPlusSize: true,
        showLikeBtn: false,
        showSeriesBrand: true,
        useOwnClickExposeAnalysis: true, // 使用自己的点击曝光埋点
        disableMainimgJump: true,
        showPromoLabel: false, // src角标图片
        showNewFlashPriceOnSale: true, // 新人闪购价格
        hideDiscountBadge: true, // 隐藏闪购左上角折扣角标
        showNewDiscountBlet: false, // 是否展示新的闪购腰带
        hideRetailPrice: true, // 隐藏划线价
        showSheinClubPriceOnSale: state.value.isPaidUser, // 是付费会员，就将价格展示为付费会员价格
        showSheinClubDiscountValueAfterPrice: true, // 不展示小号付费会员折扣标签
        showSheinClubLabelInServicesLabel: false, // 付费会员标签展示在服务标签里面
        showDetailMultiNewCard: true, // 强制使用新商卡
        showCardLabelsContainer: false, // 隐藏卡片标签
        showEstimateTag: false, // 无到手价
        showEstimatedPriceReachThreshold: false, // 无到手价
        showEstimatedPriceOnSale: false, // 无到手价
        showEstimatedPrice: false, // 无到手价
        noNeedAnyEstimatedPriceTag: true, // 不显示到手价tag
        style: {
          discountLabel: 'flashSale',
          pricesNewline: true,
          salePriceFontSize: '12px',
          lineThroughPriceFontSize: '12px',
          lineThroughPriceScale: 0.833
        }
      }
    ])
  })
}

export const getItemConfigByType = ({ type, useNewAbt }) => {
  const { isNewPic2, isNewPic3 } = state.value.commonConfig
  /**
   * 新商卡映射旧商卡枚举
   */
  const typeNewToV1Map = {
    [EItemConfigType.Pic2]: isNewPic2
      ? EItemConfigType.Pic2
      : EItemConfigType.Pic2V1,
    [EItemConfigType.Pic3]: isNewPic3
      ? EItemConfigType.Pic3
      : EItemConfigType.Pic3V1
  }

  // 是否走abt判断新商卡
  const _type = useNewAbt ? typeNewToV1Map[type] || type : type
  const itemConfig = ItemConfigMap[_type]
  if (!itemConfig) throw `$${type} ${_type} type invalid`

  return { itemConfig, trueType: _type, type }
}
