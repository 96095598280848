export const getMergeConfig = (config = {}, mergeConfig = {}) => {
  const _deepMergeKeys = ['style']

  const _newConfig = {
    ...config,
    ...mergeConfig
  }

  _deepMergeKeys.forEach(key => {
    if (
      !config[key] ||
      !mergeConfig[key] ||
      typeof mergeConfig[key] !== 'object'
    )
      return
    _newConfig[key] = {
      ...config[key],
      ...mergeConfig[key]
    }
  })

  return _newConfig
}

export const getMergeConfigList = (configList = []) => {
  return configList.reduce((finalConfig, config, index) => {
    if (!config) return finalConfig

    if (index === 0) {
      finalConfig = config
    } else {
      finalConfig = getMergeConfig(finalConfig, config)
    }
    return finalConfig
  }, {})
}

export const deepDiff = (obj1, obj2) => {
  let diff = {}

  const compare = (item1, item2, keyPath = '') => {
    // 若为对象，则递归比较
    if (
      typeof item1 === 'object' &&
      item1 !== null &&
      typeof item2 === 'object' &&
      item2 !== null
    ) {
      const keys = new Set([...Object.keys(item1), ...Object.keys(item2)])
      keys.forEach(key => {
        compare(item1[key], item2[key], keyPath ? `${keyPath}.${key}` : key)
      })
    } else {
      if (item1 !== item2) {
        // 直接比较值类型
        let pathParts = keyPath.split('.')
        let lastPart = pathParts.pop()
        let lastObj = pathParts.reduce((acc, curr) => {
          if (!acc[curr]) acc[curr] = {}
          return acc[curr]
        }, diff)
        lastObj[lastPart] = { from: item1, to: item2 }
      }
    }
  }

  compare(obj1, obj2)

  return diff
}
