export const EItemConfigType = {
  /**
   * 一行一图
   */
  Pic1: 'Pic1',
  /**
   * 一行二图
   */
  Pic2: 'Pic2',
  /**
   * 一行二图 旧商卡
   */
  Pic2V1: 'Pic2V1',
  /**
   * 一行多图
   */
  Pic3: 'Pic3',
  /**
   * 一行多图 旧商卡
   */
  Pic3V1: 'Pic3V1',
  /**
   * 一行多图 仅展示价格 新商卡 (特殊)
   */
  sPic3OnlyPrice: 'sPic3OnlyPrice',
}

export const EAtomFieldsType = {
  /**
   * 一行一图
   */
  Pic1: 'Pic1',
  /**
   * 一行二图
   */
  Pic2: 'Pic2',
  /**
   * 一行二图 旧商卡
   */
  Pic2V1: 'Pic2V1',
  /**
   * 一行多图
   */
  Pic3: 'Pic3',
  /**
   * 一行多图 旧商卡
   */
  Pic3V1: 'Pic3V1',
  /**
   * 一行多图 组合购外露 获取销售属性（特殊场景）
   */
  sPic3Sku: 'sPic3Sku',
}
